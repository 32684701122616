import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const heroSlider = new Swiper(".swiper.--heroslider", {
	autoplay: {
		enabled: true,
		delay: 6000,
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});

const ctahalfslider = new Swiper(".swiper.--ctahalfslider", {
	autoplay: {
		enabled: true,
		delay: 6000,
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});

const testimonialSlider = new Swiper(".swiper.--testimonialsslider", {
	slidesPerView: "auto",        
    spaceBetween: 50,            
    loop: true,                    
    speed: 6000,                  
    autoplay: {
      delay: 1,                    
      disableOnInteraction: false  
    },
	breakpoints: {
        640: {
          spaceBetween: 20,
        },
        768: {
          spaceBetween: 40,
        },
		1024:{
			spaceBetween: 120,
		}
      },
});

const marqueeSwiper = new Swiper(".marquee-swiper", {
    slidesPerView: "auto",        
    spaceBetween: 50,            
    loop: true,                    
    speed: 6000,                  
    autoplay: {
      delay: 1,                    
      disableOnInteraction: false  
    }
  });


  const partnersSlider = new Swiper(".swiper.--partnerSlider", {
    slidesPerView: 3,        
    spaceBetween: 80,            
    loop: true,                    
    navigation: {
      nextEl: ".block__partners__nav-next",
      prevEl: ".block__partners__nav-prev",
    },                 
  });

 const thumbswipergallery = new Swiper(".thumbswipergallery", {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });


  const thumbswipergallery2 = new Swiper(".thumbswipergallery2", {
    spaceBetween: 10,
    navigation:false,
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    thumbs: {
      swiper: thumbswipergallery,
    },
  });