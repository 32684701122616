// let isSticky = false; 

// window.addEventListener('scroll', throttle(handleScroll, 50));

// function handleScroll() {
//   const header = document.querySelector('.header');
//   const headerHeight = header.offsetHeight;

//   if (window.scrollY >= headerHeight && !isSticky) {
//     header.classList.add('stickyheader');
//     document.body.style.paddingTop = `${headerHeight}px`;
//     isSticky = true;
//   } else if (window.scrollY < headerHeight + 500 && isSticky) {
//     header.classList.remove('stickyheader');
//     document.body.style.paddingTop = '0';
//     isSticky = false; 
//   }
// }

// function throttle(fn, wait) {
//   let lastTime = 0;
//   return function (...args) {
//     const now = Date.now();
//     if (now - lastTime >= wait) {
//       lastTime = now;
//       fn.apply(this, args);
//     }
//   };
// }

/** Sticky Header */
const header  = document.querySelector('.header');
const scrollwatcher = document.createElement('div');
scrollwatcher.setAttribute('data-scroll-watcher','');
header.before(scrollwatcher);

const navObserver = new IntersectionObserver((entries, observer) => {
    header.classList.toggle('stickying', !entries[0].isIntersecting);
}, {rootMargin: `${header.offsetHeight}px 0px 0px 0px`});

navObserver.observe(scrollwatcher);



/** Back to top */
const backtopTop  = document.querySelector('.back_to_top');
const footer  = document.querySelector('.footer');
const scrollwatcherbacktopTop = document.createElement('div');
scrollwatcherbacktopTop.setAttribute('data-scroll-watcher','');
backtopTop.before(scrollwatcherbacktopTop);


const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          backToTopButton.classList.remove('visible');
          backToTopButton.classList.add('hidden');
        } else {
          backToTopButton.classList.remove('hidden');
          backToTopButton.classList.add('visible');
        }
      });
    },
    { threshold: 0.1 } // Trigger when 10% of the content is visible
  );
  

const bacttopObserve = new IntersectionObserver((entries, observer) => {
    backtopTop.classList.toggle('active', entries[0].isIntersecting);
}, { threshold: 0.1 } );
bacttopObserve.observe(footer);
